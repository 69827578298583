import { Dispatch } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

import Deserializer from '~/services/Deserializer'
import EmpriseGroup from '~/services/EmpriseGroup'
import ErrorHandler from '~/utils/errorHandler'

import { STORAGE } from '~/constants'
import {
  SignUpInput,
  SignInInput,
  ForgotPasswordInput,
  ResetPasswordInput,
  AuthThirdPartyDTO
} from '~/interfaces/inputs'
import { ThunkActionCreator } from '~/interfaces/redux-thunk'
import { Profile } from '~/models/Profile'

import { actions } from './user.slice'

// Actions

export const authtorize: ThunkActionCreator<Promise<void>> = ({
  jwt
}: {
  jwt: string
}) => async (dispatch: Dispatch): Promise<void> => {
  try {
    EmpriseGroup.setAuthToken(jwt)
    let decodedToken: any

    if (jwt) {
      Cookies.set('token', jwt)
      localStorage.setItem(STORAGE.authToken, jwt)
      decodedToken = jwtDecode(jwt)

      dispatch(
        actions.login({
          ...decodedToken,
          token: jwt
        })
      )
    }

    const resp = await EmpriseGroup.getUser(decodedToken.user_id)
    const data = await Deserializer.deserialize(resp)
    let dealer
    let business

    if (data.dealer) {
      dealer = await Deserializer.deserialize(data.dealer)
    }

    if (data.business) {
      business = await Deserializer.deserialize(data.business)
    }

    dispatch(actions.login({ ...data, dealer, business }))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * User send reset link
 */
export const sendResetLink = (values: ForgotPasswordInput): Promise<{}> =>
  EmpriseGroup.sendResetLink(values).catch((error: any) => {
    throw new ErrorHandler(error)
  })

/**
 * User resend confirmation email
 */
export const resendConfirmationEmail = (email: string): Promise<{}> =>
  EmpriseGroup.resendConfirmationEmail(email).catch((error: any) => {
    throw new ErrorHandler(error)
  })

/**
 * User sign up with email
 */
export const signup: ThunkActionCreator<Promise<void>> = (
  user: SignUpInput
) => async (): Promise<void> => {
  try {
    await EmpriseGroup.createUser(user)

    localStorage.setItem(STORAGE.userEmail, user.email)
  } catch (error) {
    throw new ErrorHandler(error, true)
  }
}

/**
 * User confirm email
 */
export const signupConfirmEmail: ThunkActionCreator<Promise<void>> = (
  token: string
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const resp = await EmpriseGroup.confirmUser(token)

    dispatch<any>(authtorize(resp))
  } catch (error) {
    throw new ErrorHandler(error, true)
  }
}

/**
 * User login action
 */
export const login: ThunkActionCreator<Promise<void>> = (
  user: SignInInput
) => async (dispatch: Dispatch): Promise<any> => {
  try {
    const resp = await EmpriseGroup.login(user)
    await dispatch<any>(authtorize(resp))
    return resp
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * authorization with
 */
export const authorizationWith: ThunkActionCreator<Promise<void>> = (
  payload: AuthThirdPartyDTO
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const resp = await EmpriseGroup.authorizationWith(payload)

    dispatch<any>(authtorize(resp))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * User reset password action
 */
export const resetPassword: ThunkActionCreator<Promise<void>> = (
  values: ResetPasswordInput
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const token = localStorage.getItem(STORAGE.resetToken) || ''

    const resp = await EmpriseGroup.resetPassword({
      token,
      ...values
    })

    localStorage.removeItem(STORAGE.userEmail)
    localStorage.removeItem(STORAGE.resetToken)

    dispatch<any>(authtorize(resp))
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * User logout action
 */
export const logout: ThunkActionCreator<void> = () => (
  dispatch: Dispatch
): void => {
  try {
    EmpriseGroup.setAuthToken()
    localStorage.clear()
    if (Cookies.get('token')) {
      Cookies.remove('token')
    }
    dispatch(actions.logout())
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Delete user account
 */
export const deleteUserAccount: ThunkActionCreator<void> = () => async (
  dispatch: Dispatch,
  getState
): Promise<void> => {
  try {
    const { id } = getState().user
    await EmpriseGroup.deleteUser(id)

    dispatch<any>(logout())
  } catch (error) {
    throw new ErrorHandler(error)
  }
}

/**
 * Update user
 */
export const updateUser: ThunkActionCreator<void> = (values: Profile) => async (
  dispatch: Dispatch,
  getState
): Promise<void> => {
  try {
    const { user_id } = getState().user
    const {
      data: { attributes, ...data }
    } = await EmpriseGroup.updateUser(user_id, values)

    dispatch<any>(
      actions.updateUser({
        ...attributes,
        ...data
      })
    )
  } catch (error) {
    throw new ErrorHandler(error)
  }
}
