import { linksCategories } from '~/data/linksCategories'

import { IS_BOAT } from '~/brands'
import {
  IS_BROWSER,
  MUTUAL_CONVERSION_FIELDS,
  DIFFERENCE_METERS_FEETS,
  VALID_KEYS
} from '~/constants'

/**
 * Deep merges two objets.
 * @param  {Object} object destination object
 * @param  {Object} source source obejct
 *
 * @returns {Object} new object
 */
export const merge = (obj: any, source: object): object => {
  if (obj === source) return obj
  const newValue: any = {
    ...obj,
    ...source
  }

  Object.entries(source).forEach(([key, value]) => {
    newValue[key] =
      obj[key] && typeof obj[key] === 'object' ? merge(obj[key], value) : value
  })

  return newValue
}

/**
 * Return formatted currency
 *
 * @param {number} value - value
 */
export const formatCurrency = (number: number, options?: {}): string => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options
  })
  return formatter.format(number).replace('A$', 'AUD ')
}

export const formatOption = ({
  option,
  optionValue,
  optionLabel
}: {
  option: any
  optionValue: string
  optionLabel: string
}): { value: any; label: any } => {
  if (
    typeof option === 'object' &&
    optionValue in option &&
    optionLabel in option
  ) {
    const { [optionValue]: value, [optionLabel]: label, ...rest } = option
    return { value, label, ...rest }
  }
  if (typeof option === 'object') {
    return option
  }

  return { value: option, label: option }
}

export const safelyParseJSON = (json): any => {
  let parsed

  try {
    parsed = JSON.parse(json)
  } catch (e) {
    console.log('e', e)
  }

  return parsed
}

export const safelyStringifyJSON = (json): any => {
  let parsed

  try {
    parsed = JSON.stringify(json)
  } catch (e) {
    console.log('e', e)
  }

  return parsed
}

export const isOfType = {
  null(x) {
    return x === null
  },
  undefined(x) {
    return x === undefined
  },
  nil(x) {
    return this.null(x) || this.undefined(x)
  },
  string(x) {
    return !this.nil(x) && (typeof x === 'string' || x instanceof String)
  },
  number(x) {
    return (
      !this.nil(x) &&
      // eslint-disable-next-line no-restricted-globals
      ((!isNaN(x) && isFinite(x) && typeof x === 'number') ||
        x instanceof Number)
    )
  },
  boolean(x) {
    return !this.nil(x) && (typeof x === 'boolean' || x instanceof Boolean)
  },
  array(x) {
    return !this.nil(x) && Array.isArray(x)
  },

  object(x) {
    return {}.toString.call(x) === '[object Object]'
  },
  type(x, X) {
    return !this.nil(x) && x instanceof X
  },
  set(x) {
    return this.type(x, Set)
  },
  map(x) {
    return this.type(x, Map)
  },
  date(x) {
    return this.type(x, Date)
  }
}

export const buildLineGraph = async (
  data: { [key: string]: number },
  count: number
) => {
  const { DateTime } = await import('luxon')

  const currentStatistics = Object.entries(data).map(([key, value]) => {
    const [date] = key.split(' ')

    return {
      date: DateTime.fromISO(date),
      value
    }
  })

  const currentDate = DateTime.local()
  const normalized = new Array(count).fill(null).map((_v, index, array) => {
    const date = currentDate.minus({ days: array.length - (index + 1) })

    const current = currentStatistics.find((d) => d.date.hasSame(date, 'day'))

    if (!current) {
      return {
        date: date.toFormat('d MMM'),
        value: 0
      }
    }

    return {
      ...current,
      date: current.date.toFormat('d MMM')
    }
  })

  return normalized
}

export function capitalizeFirstLetter(string: string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)
}

export function snakeCaseToCapitalize(string: string) {
  return capitalizeFirstLetter(string?.replace(/_/g, ' '))
}

export const canStartRequestWithQuery = (query) => {
  if (!IS_BROWSER) return false

  return !(window.location.search && !Object.keys(query).length)
}

export const createRangeStep = (
  min: number,
  max: number,
  intervals: { value: number; step: number }[],
  formatLabel?: (v: any) => string
): { value: number; label: string }[] => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(+min) || isNaN(+max)) return []

  // const currentIntervals = intervals.filter(
  //   (v) => v.value > min && v.value <= max
  // )
  const currentIntervals = intervals

  const { range } = currentIntervals.reduce(
    (acc, interval, index) => {
      const createRange = () => {
        const nextIndex =
          currentIntervals.length - 1 !== index ? index + 1 : index
        if (acc.value >= currentIntervals[nextIndex].value) return

        acc.value += interval.step
        acc.range.push({
          value: acc.value,
          label: String(formatLabel ? formatLabel(acc.value) : acc.value)
        })

        return createRange()
      }

      createRange()

      return acc
    },
    { value: 0, range: [] }
  )

  return [
    {
      value: min,
      label: 'Min'
    },
    ...range,
    {
      value: max,
      label: 'Max'
    }
  ]
}

export const equalsArray = (a: any[], b: any[]): boolean =>
  a.length === b.length && a.every((v, i) => v === b[i])

export const measureConvertion = (
  value: string,
  name: string,
  change: (field: string, value: any) => void
) => {
  const metersFields =
    MUTUAL_CONVERSION_FIELDS.filter(
      (fieldName) => !fieldName.includes('feet')
    ) || []
  const normalizedValue = Number(value.split(',').join('')) || 0
  let changingField = ''
  let convertedValue = null

  if (metersFields.includes(name)) {
    changingField = `${name}_feet`
    convertedValue = normalizedValue * DIFFERENCE_METERS_FEETS
  } else {
    changingField = name
      .split('_')
      .filter((word) => word !== 'feet')
      .join('_')

    convertedValue = normalizedValue / DIFFERENCE_METERS_FEETS
  }

  if (convertedValue > 0) {
    change(changingField, parseFloat(String(convertedValue.toFixed(2))))
  } else {
    change(changingField, null)
  }
}

export const cleaningPairField = (
  name: string,
  change: (field: string, value: any) => void
) => {
  const metersFields =
    MUTUAL_CONVERSION_FIELDS.filter(
      (fieldName) => !fieldName.includes('feet')
    ) || []
  let changingField = ''

  if (metersFields.includes(name)) {
    changingField = `${name}_feet`
    change(changingField, { min: null, max: null })
  } else {
    changingField = name
      .split('_')
      .filter((word) => word !== 'feet')
      .join('_')

    change(changingField, { min: null, max: null })
  }
}

export const validatingInput = (event) => {
  const { value } = event.target
  if (!VALID_KEYS.includes(event.key) && value.length <= 24) {
    event.preventDefault()
  }
  if (value.length > 24) {
    event.preventDefault()
  }
}

export const normalizeTextContent = (text) => {
  if (text || typeof text === 'number') {
    return text
  }
  return 'N/A'
}

export const transformZeroToValue = (
  value: string | number
): string | number => {
  if (Number(value) === 0) {
    return 'POA'
  }
  return formatCurrency(Number(value))
}

export const formatPrice = (price: number | string): string => {
  return `$ ${price}`
}

export const isHideNavigation = (
  isHidePackages: boolean,
  step: number
): boolean => {
  if (IS_BOAT) {
    const isHideBoatWithoutPacgkages = !isHidePackages && step > 6
    const isHideBoatWithPacgkages = isHidePackages && step > 7

    return isHideBoatWithoutPacgkages || isHideBoatWithPacgkages
  }

  if (!IS_BOAT) {
    const isHideRvsWithoutPacgkages = !isHidePackages && step > 5
    const isHideRvsWithPacgkages = isHidePackages && step > 6

    return isHideRvsWithoutPacgkages || isHideRvsWithPacgkages
  }
}

export const openExternalLink = ({
  link,
  type = '_self'
}: {
  link: string
  type?: '_self' | '_blank'
}) => window.open(link, type)

export const getWizardFormStepTitles = (steps) =>
  steps.map((step) => step.title)

interface ListingStepDTO {
  creation_step: number
  creation_step_name: string
}

export const getFormattedListingStepData = (
  step: number,
  stepTitles: string[]
): ListingStepDTO => {
  const currentStepName = stepTitles[step]

  return {
    creation_step: step,
    creation_step_name: currentStepName
  }
}

export const getCategoryLogo = (typeName: string): string => {
  const category = linksCategories.find(
    (category) => category.name === typeName
  )

  return category?.logo || '/static/images/categories/boat/sailboat.png'
}
