import { Utf8AsciiLatin1Encoding } from 'crypto'

import axios from 'axios'
import qs from 'qs'

import Api from '~/utils/Api'

import {
  AuthThirdPartyDTO,
  BusinessFiltersDTO,
  EnquiryDTO,
  ForgotPasswordDTO,
  ResetPasswordDTO,
  SignInDTO,
  SignUpDTO
} from '~/interfaces/inputs'
import { Dealer, DealerDTO, DealerFiltersDTO } from '~/models/Dealer'
import { EnquryRespons, FinanceInfoResponse } from '~/models/Enquiry'
import { BlogArticlePaginationModel, BlogArticleParams } from '~/models/Hemax'
import {
  ArticleListing,
  ArticleListingParams,
  HomePageModel,
  Listing,
  ListingsModel,
  SimilarListingResponse
} from '~/models/Listing/interface'
import { AdvancedFeature, PackageParams } from '~/models/Package'
import { Pagy } from '~/models/Pagy'
import { Profile } from '~/models/Profile'
import { PayPalCheckoutLink } from '~/models/User'

import { IS_BROWSER } from '../constants/index'

const calculateChecksum = async (file: any): Promise<any> => {
  const crypto = (await import('crypto')).default

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const md5sum = crypto.createHash('md5')

    reader.readAsBinaryString(file)

    reader.onload = (e: any): void => {
      md5sum.update(e.target.result, 'binary' as Utf8AsciiLatin1Encoding)
      resolve(md5sum.digest('base64'))
    }

    reader.onerror = (e: any): void => {
      reject(e)
    }
  })
}

class EmpriseGroupClass extends Api {
  /**
   * User login
   */
  login = (user: SignInDTO): Promise<ResponseObject> =>
    this.post('/api/v1/user/sessions', {
      data: user
    })
  /** s
   * Send reset link to email
   */
  sendResetLink = (forgot_password: ForgotPasswordDTO): Promise<any> =>
    this.post('/api/v1/user/passwords/emails', {
      data: forgot_password
    })
  /**
   * Resend confirmation email
   */
  resendConfirmationEmail = (email: string): Promise<any> =>
    this.post('/api/v1/user/resend_confirmation', {
      data: { email }
    })
  /**
   * Create a new user
   */
  createUser = (user: SignUpDTO): Promise<any> =>
    this.post('/api/v1/users', { data: { user } })
  /**
   * Confirm user link
   */
  confirmUser = (confirmation_token: string): Promise<any> =>
    this.post(`/api/v1/user/confirmations`, { data: { confirmation_token } })
  /**
   * Reset password
   */
  resetPassword = (values: ResetPasswordDTO): Promise<any> =>
    this.post(`/api/v1/user/passwords`, { data: values })
  /**
   * Get user data
   */
  getUser = (user_id): Promise<ResponseObject> =>
    this.get(`/api/v1/users/${user_id}`)
  /**
   * signin with google
   */
  authorizationWith = (data: AuthThirdPartyDTO): Promise<any> =>
    this.post('/api/v1/user/third_party_auth', {
      data
    })
  /**
   * Delete user
   */
  deleteUser = (id: string | number): Promise<any> =>
    this.del(`/api/v1/users/${id}`)
  /**
   * Refresh token
   */
  refreshToken = (): Promise<any> => this.post('/api/v1/user/tokens')
  /**
   * Update user
   */
  updateUser = (user_id: string | number, data: Profile): Promise<any> =>
    this.put(`/api/v1/users/${user_id}`, { data })
  /**
   * Direct upload helper
   */
  _directUpload = async (
    file: any
  ): Promise<{ signed_id: string; origin: object }> => {
    try {
      const checksum = await calculateChecksum(file)

      const {
        direct_upload: { url, headers },
        // id,
        signed_id
      } = await this.post('/rails/active_storage/direct_uploads', {
        data: {
          blob: {
            filename: file.name || 'image',
            byte_size: file.size,
            content_type: file.type,
            checksum
          }
        }
      })

      await axios.put(url, file, {
        headers
      })

      return { signed_id, origin: file }
    } catch (error) {
      return Promise.reject(error)
    }
  }
  /**
   * Direct upload of one file
   */
  directUploadOne = (file: any): Promise<any> => this._directUpload(file)
  /**
   * Direct upload of all files
   */
  directUploadAll = (
    files: any
  ): Promise<Array<{ signed_id: string; origin: object }>> =>
    Promise.all(files.map((file: any) => this._directUpload(file)))
  /**
   * Get main categories
   */
  getMainCategories = (): Promise<any> =>
    this.get(`/api/v1/types`, { deserialize: true })
  /**
   * Get Categories
   */
  getCategories = (params?: {
    type_id?: string
    type_name?: string
  }): Promise<any> =>
    this.get(
      `/api/v1/categories${qs.stringify(params, { addQueryPrefix: !!params })}`
    )
  /**
   * Get brands
   */
  getBrands = (params?: {
    with_listings?: boolean
    from_cds?: boolean
  }): Promise<any> => this.get('/api/v1/makes', { params })
  /**
   * Get models
   */
  getModels = (params: {
    make_id?: string
    make_name?: string[]
    from_cds?: boolean
  }): Promise<any> =>
    this.get(
      `/api/v1/models${qs.stringify(params, {
        addQueryPrefix: !!params,
        arrayFormat: 'brackets'
      })}`
    )
  /**
   * Get series
   */
  getSeries = (params: {
    model_id?: string
    model_name?: string
  }): Promise<any> =>
    this.get(
      `/api/v1/series${qs.stringify(params, {
        addQueryPrefix: !!params,
        arrayFormat: 'brackets'
      })}`
    )
  /**
   * Get main types
   */
  getMainTypes = (): Promise<any> => this.get('/api/v1/main_usage_types')
  /**
   * Get engine types
   */
  getEngineTypes = (): Promise<any> => this.get('/api/v1/engine_types')
  /**
   * Get fuel types
   */
  getFuelTypes = (): Promise<any> => this.get('/api/v1/fuel_types')
  /**
   * Get detailed types
   */
  getDetailedTypes = (): Promise<any> => this.get('/api/v1/detailed_types')
  /**
   * Get hull types
   */
  getHullTypes = (): Promise<any> => this.get('/api/v1/hull_types')
  /**
   * Get hull materials
   */
  getHullMaterials = (): Promise<any> => this.get('/api/v1/hull_materials')
  /**
   * Get steerings
   */
  getSteerings = (): Promise<any> => this.get('/api/v1/steerings')
  /**
   * Get drive types
   */
  getDriveTypes = (): Promise<any> => this.get('/api/v1/drive_types')
  /**
   * Get extra highlights (additional features)
   */
  getExtraHighlights = (): Promise<any> => this.get('/api/v1/extra_highlights')
  /**
   * Get locations
   */
  getLocations = (): Promise<any> => this.get('/api/v1/locations')
  /**
   * Get seller offered services
   */
  getSellerOfferedServices = (): Promise<any> =>
    this.get('/api/v1/seller_offered_services')
  /**
   * Create listing
   */
  createListing = (listing: Partial<Listing>): Promise<Listing> =>
    this.post('/api/v1/user/listings', {
      data: {
        listing: {
          ...listing,
          images: listing?.images?.map((image) => image.signed_id)
        }
      }
    })

  /**
   * Update listing
   */
  updateListing = (id: string, listing: Partial<Listing>): Promise<Listing> => {
    return this.put(`/api/v1/user/listings/${id}`, {
      data: {
        listing: {
          ...listing,
          images: listing?.images?.map((image) => image.signed_id)
        }
      }
    })
  }
  /**
   * Get listings
   */
  getListings = (params): Promise<ListingsModel> =>
    this.get(
      `/api/v1/listings${qs.stringify(params, {
        addQueryPrefix: !!params,
        arrayFormat: 'brackets'
      })}`
    )
  /**
   * Delete listing
   */
  deleteListing = (id: number | string): Promise<void> =>
    this.del(`/api/v1/user/listings/${id}`)
  /**
   * Get listing
   */
  getListing = (id: number | string): Promise<Listing> =>
    this.get(`/api/v1/listings/${id}`)
  /**
   * Get listing similar
   */
  getListingSimilar = (id: number | string): Promise<SimilarListingResponse> =>
    this.get(`/api/v1/listings/${id}/similars`)
  /**
   * Get user listings
   */
  getUserListings = ({
    page,
    sorting,
    by_status
  }: {
    page?: number
    sorting?: any
    by_status?: string
  }): Promise<ListingsModel> =>
    this.get('/api/v1/user/listings', { params: { page, sorting, by_status } })
  /**
   * Get user listing
   */
  getUserListing = (id: number | string): Promise<Listing> =>
    this.get(`/api/v1/user/listings/${id}`)
  /**
   * add favorite listing
   */
  addFavoriteListing = (listing_id: number): Promise<any> =>
    this.post('/api/v1/user/favorite_listings', { data: { listing_id } })
  /**
   * delete favorite listing
   */
  deleteFavoriteListing = (listing_id: number): Promise<any> =>
    this.del('/api/v1/user/favorite_listings', { data: { listing_id } })
  /**
   * set favorite listing
   */
  setFavoriteListing = (
    listing_id: number,
    favorite: boolean
  ): Promise<any> => {
    if (favorite) {
      return this.deleteFavoriteListing(listing_id)
    }

    return this.addFavoriteListing(listing_id)
  }
  /**
   * get favorites listings
   */
  getFavoriteListings = (page = 1): Promise<ListingsModel> =>
    this.get(`/api/v1/user/favorite_listings?page=${page}`)
  /**
   * Create lead (Enquiry)
   */
  createLead = (lead: EnquiryDTO): Promise<SimilarListingResponse> =>
    this.post('/api/v1/leads', { data: { lead } })
  /**
   * Get leads (Enquiries)
   */
  getLeads = ({
    page,
    sorting
  }: {
    page?: number
    sorting?: string
  }): Promise<EnquryRespons> =>
    this.get('/api/v1/user/leads', { params: { page, sorting } })
  /**
   * Change status of lead (Enquiry)
   */
  changeStatusLead = ({
    id,
    status
  }: {
    id: string
    status: string
  }): Promise<any> =>
    this.put(`/api/v1/user/leads/${id}`, { data: { lead: { status } } })
  /**
   * Change notes of lead (Enquiry)
   */
  setNotesEnquiry = ({
    id,
    notes
  }: {
    id: string
    notes: string
  }): Promise<any> =>
    this.put(`/api/v1/user/leads/${id}`, {
      data: { lead: { seller_notes: notes } }
    })
  /**
   * Get dashboard
   */
  getDashboard = (): Promise<any> => this.get('/api/v1/user/dashboards')
  /**
   * Create business
   */
  createBusiness = (business): Promise<any> =>
    this.post('/api/v1/user/businesses', {
      data: { business: { ...business, logo: business.logo.signed_id } }
    })
  /**
   * Update business
   */
  updateBusiness = (business): Promise<any> =>
    this.put('/api/v1/user/businesses', {
      data: { business: { ...business, logo: business.logo.signed_id } }
    })
  /**
   * Get business
   */
  getBusiness = (id: number | string): Promise<any> =>
    this.get(`/api/v1/businesses/${id}`)
  /**
   * Get businesses
   */
  getBusinesses = (params: BusinessFiltersDTO): Promise<any> =>
    this.get(
      `/api/v1/businesses${qs.stringify(params, { addQueryPrefix: !!params })}`
    )
  /**
   * Get business categories
   */
  getBusinessCategories = (): Promise<any> =>
    this.get(`/api/v1/business_categories`)
  /**
   * Get my business
   */
  getMyBusiness = (): Promise<any> => this.get('/api/v1/user/businesses')
  /**
   * Create dealer
   */
  createDealer = (dealer: DealerDTO): Promise<Dealer> =>
    this.post('/api/v1/user/dealers', {
      data: { dealer: { ...dealer, logo: dealer.logo.signed_id } }
    })
  /**
   * Update dealer
   */
  updateDealer = (dealer: DealerDTO): Promise<Dealer> =>
    this.put('/api/v1/user/dealers', {
      data: { dealer: { ...dealer, logo: dealer.logo.signed_id } }
    })
  /**
   * Get dealer
   */
  getDealer = (id, page?: string | number): Promise<any> =>
    this.get(`/api/v1/dealers/${id}`, { params: { page } })
  /**
   * Get businesses
   */
  getDealers = (params: DealerFiltersDTO): Promise<any> =>
    this.get(
      `/api/v1/dealers${qs.stringify(params, { addQueryPrefix: !!params })}`
    )
  /**
   * Get my dealer
   */
  getMyDealer = (): Promise<Dealer> => this.get('/api/v1/user/dealers')
  /**
   * Get dealer options
   */
  getDealerOptions = (): Promise<any> => this.get('/api/v1/select_dealers')
  /**
   * Get packages by type
   */
  getPackages = (by_type: PackageParams): Promise<any> =>
    this.get('/api/v1/packages', { params: { by_type } })
  /**
   * Create payment
   */
  createStripePayment = (data: PaymentDTO): Promise<any> =>
    this.post('/api/v1/payments/stripes/orders', { data })
  /**
   * Create payment
   */
  createStripeSubscription = (data: PaymentDTO): Promise<any> =>
    this.post('/api/v1/payments/stripes/subscriptions', { data })
  /**
   * Get listing slugs
   */
  getListingSlugs = (): Promise<{ slugs: string[] }> =>
    this.get('/api/v1/build/listings', {
      auth: {
        username: process.env.BUILD_AUTH_USERNAME,
        password: process.env.BUILD_AUTH_PASSWORD
      }
    })
  /**
   * Get dealer slugs
   */
  getDealerSlugs = (): Promise<{ slugs: string[] }> =>
    this.get('/api/v1/build/dealers', {
      auth: {
        username: process.env.BUILD_AUTH_USERNAME,
        password: process.env.BUILD_AUTH_PASSWORD
      }
    })
  /**
   * Get business slugs
   */
  getBusinessSlugs = (): Promise<{ slugs: string[] }> =>
    this.get('/api/v1/build/businesses', {
      auth: {
        username: process.env.BUILD_AUTH_USERNAME,
        password: process.env.BUILD_AUTH_PASSWORD
      }
    })
  /**
   * Get saved searches
   */
  getSavedSearches = (
    page?: number
  ): Promise<{
    pagy: Pagy
    saved_searches: ResponseObject<{ query: string }>
  }> => this.get('/api/v1/user/saved_searches', { params: { page } })
  /**
   * Set saved searches
   */
  saveSearch = ({
    query,
    name
  }: {
    query: string
    name: string
  }): Promise<ResponseObject<{ query: string }>> =>
    this.post('/api/v1/user/saved_searches', { data: { query, name } })
  /**
   * Remove saved searches
   */
  removeSearch = (
    id: string | number
  ): Promise<ResponseObject<{ query: string }>> =>
    this.del(`/api/v1/user/saved_searches/${id}`)
  /**
   * Get home page data
   */
  getHomepage = (): Promise<HomePageModel> => this.get('/api/v1/home_pages')
  /**
   * email subscribe
   */
  emailSubscribe = (email: string): Promise<any> =>
    this.post('/api/v1/subscriptions', { data: { email } })
  /**
   * repayment calculations
   */
  repaymentCalculations = ({
    loan_amount,
    loan_term,
    interest_rate,
    payment_freq
  }): Promise<any> =>
    this.post('/api/v1/repayment_calculations', {
      data: { loan_amount, loan_term, interest_rate, payment_freq }
    })
  /**
   * get finance calculation
   */
  getFinanceCalculation = ({
    loan_amount
  }): Promise<CreditOneLoanCalculation> =>
    this.get('/loan.repayment.calculate', {
      baseURL: 'https://www.creditone.com.au/api',
      params: {
        token: process.env.CREDITONE_TOKEN,
        loan_amount,
        is_new: true
      }
    })
  /**
   * get last listings
   */
  getLastListings = (): Promise<Listing[]> => this.get('/api/v1/last_listings')
  /**
   * get finance info
   */
  getFinanceInfo = (slug: string): Promise<FinanceInfoResponse> =>
    this.get(`/api/v1/stratton_widgets/${slug}`)

  getAdvancedFeatures = (): Promise<AdvancedFeature[]> =>
    this.get(`/api/v1/advanced_features`)

  /**
   * Create PayPal order checkout
   */
  createPaypalCheckout = (
    data: PayPalCheckoutDTO
  ): Promise<PayPalCheckoutLink> =>
    this.post('/api/v1/payments/paypals/orders', { data })

  /**
   * Create PayPal subscription checkout
   */
  createPaypalSubscriptionCheckout = (
    data: PayPalCheckoutDTO
  ): Promise<PayPalCheckoutLink> =>
    this.post('/api/v1/payments/paypals/subscriptions', { data })

  /**
   * Create order after PayPal payment
   */
  createPaypalOrder = (token: string): Promise<void> =>
    this.patch(`/api/v1/payments/paypals/orders/${token}`)

  /**
   * Get blog articles
   */
  getBlogArticles = (
    params: BlogArticleParams
  ): Promise<BlogArticlePaginationModel> =>
    this.get(`/api/v1/articles`, {
      params: {
        limit: params.limit,
        by_keyword: params.byKeyword
      }
    })

  /**
   * Get article listings
   */
  getArticleListings = (
    params: ArticleListingParams
  ): Promise<ArticleListing[]> =>
    this.get(`/api/v1/article/listings`, { params, deserialize: true })

  /**
   * Toggles the listing archive status
   */
  toggleListingArchiveStatus = (listingId: number | string): Promise<void> =>
    this.patch(`/api/v1/user/listings/${listingId}/archivate`)
}

type CreditOneLoanCalculation = {
  success: boolean
  interest_rate: number
  comparison_rate: number
  comparison_base: number
  term_in_months: number
  balloon: number
  loan_amount: number
  repayment: {
    weekly: number
    monthly: number
    yearly: number
  }
}

type PaymentDTO = {
  subject_type: 'Listing' | 'Dealer' | 'Business'
  subject_id: string | number
  package_id?: string | number
  feature_ids?: any[]
  payment_method_id?: string
  update_me_with_latest_news?: boolean
}

type PayPalCheckoutDTO = PaymentDTO & {
  return_url: string
  cancel_url: string
}

const baseURL = process.env.PROXY_API
  ? IS_BROWSER
    ? '/'
    : process.env.PROXY_API
  : process.env.REACT_APP_API

const EmpriseGroup = new EmpriseGroupClass({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

export default EmpriseGroup
