import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import {
  PaginationClassKey,
  PaginationItemClassKey,
  AutocompleteClassKey
} from '@material-ui/lab'

interface Overrides extends CoreOverrides {
  MuiPagination?:
    | Partial<Record<PaginationClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined
  MuiPaginationItem?:
    | Partial<
        Record<PaginationItemClassKey, CSSProperties | (() => CSSProperties)>
      >
    | undefined
  MuiAutocomplete?:
    | Partial<
        Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>
      >
    | undefined
}

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      'html, body': {
        minHeight: '100vh',
        margin: 0,
        fontFamily: 'Nunito Sans, sans-serif',
        display: 'flex',
        flexDirection: 'column'
      },
      '#__next': {
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',

        margin: 0
      },
      '.full-image': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center'
      },
      '.dont-break-out': {
        // These are technically the same, but use both
        overflowWrap: 'break-word',
        wordWrap: 'break-word',

        // Adds a hyphen where the word breaks, if supported (No Blink)
        '-ms-hyphens': 'auto',
        '-moz-hyphens': 'auto',
        '-webkit-hyphens': 'auto',
        hyphens: 'auto'
      },
      '.next-image-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',

        '& > div': {
          minWidth: '100%'
        }
      },
      '.line-clamp-2': {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
      },
      '.format-parsed-text': {
        '& p': {
          margin: 0
        }
      },
      '.styled-scroll': {
        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
          width: '4px',
          backgroundColor: '#E9ECEE',
          borderRadius: 4
        },
        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
          backgroundColor: '#C5CED5',
          borderRadius: 4
        }
      },
      'input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none'
      },
      '.MuiAutocomplete-popper': {
        overflowWrap: 'anywhere',
        // @ts-ignore
        zIndex: '1200 !important'
      },
      '.grecaptcha-badge': {
        zIndex: 9999
      },
      '.go318386747': {
        justifyContent: 'flex-start',
        margin: '0 10px 0 0'
      },
      '.toast': {
        minHeight: '64px',
        maxHeight: '800px',
        fontFamily: 'Nunito Sans, sans-serif',
        lineHeight: '1.5',
        color: '#fff',
        padding: 0,
        fontWeight: '700',
        width: '320px',
        borderRadius: '5px',
        maxWidth: '600px',
        '@media (max-width: 1200px)': {
          margin: '5px 5px 5px 50px'
        }
      },
      '.toastButton': {
        color: '#fff',
        background: 'transparent',
        outline: 'none',
        border: 'none',
        fontSize: '22px',
        cursor: 'pointer',
        opacity: '0.7',
        transition: '0.3s ease'
      },
      '.toastSuccess': {
        backgroundColor: '#07bc0c',
        maxWidth: '600px'
      },
      '.toastError': {
        backgroundColor: '#E74C3C'
      },
      '.toastButton:hover': {
        opacity: '1'
      },
      '.toast__progress-bar': {
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '5px',
        zIndex: 999,
        opacity: '0.7',
        transformOrigin: 'left',
        backgroundColor: 'rgba(255, 255, 255, .8)'
      },
      '.toast__progress-bar--animated': {
        animation: 'toast__trackProgress linear 1 forwards'
      },
      '.toast__progress-bar--controlled': {
        transition: 'transform 0.2s'
      },
      '.toast__progress-bar--rtl': {
        right: '0',
        left: 'initial',
        transformOrigin: 'right'
      },
      '@keyframes toast__trackProgress': {
        '0%': {
          transform: 'scaleX(1)'
        },
        '100%': {
          transform: 'scaleX(0)'
        }
      }
    }
  },

  MuiButton: {
    root: {
      textTransform: 'none',
      fontFamily: 'Prompt, sans-serif',
      fontWeight: 400,
      '@media(max-width: 900px)': {
        fontSize: 16,
        fontWeight: 500
      }
    }
  },
  MuiOutlinedInput: {
    root: {
      background: '#fff'
    },
    input: {
      padding: '16px 14px',
      fontSize: '16px'
    }
  },
  MuiTableCell: {
    root: {
      fontSize: 15
    }
  },

  MuiPagination: {
    ul: {
      borderRadius: 4,
      background: '#fff',
      border: `1px solid #E5E5E5`,
      '& li': {
        '&:not(:last-child)': {
          borderRight: `1px solid #E5E5E5`
        },
        '& button': {
          borderRadius: 0,
          margin: 0
        },
        '&:first-of-type': {
          '& button': {
            borderRadius: '4px 0px 0px 4px'
          }
        },
        '&:last-of-type': {
          '& button': {
            borderRadius: '0px 4px 4px 0px'
          }
        }
      }
    }
  }
}

export default overrides
